<template>
  <b-overlay
      :show="!userData"
      rounded="sm"
  >
    <div>

      <user-edit-tab-account
          v-if="userData"
          :selfie="selfie"
          :user-data="userData"
          class="mt-2 pt-75"
          @submitEdit="submitEdit"
      />
    </div>


  </b-overlay>
</template>

<script>
import { BAlert, BCard, BLink, BOverlay, BTab, BTabs, } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import { UsersGetRequest, UsersUpdateByAdminRequest } from '@/libs/Api/Users'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from '@/libs/Helper'
import { OrderGetAllForBoostersRequest, OrderGetAllRequest, OrderGetByUserIdRequest } from '@/libs/Api/Order'

export default {
  title: 'Edit user',
  data() {
    return {
      userId: this.$route.params.id,
      userData: null,
      selfie: null,

    }
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    UserEditTabAccount,
  },
  async created() {
    await Promise.all([
      this.getUser(),
    ])
  },
  methods: {

    async getUser() {
      let _this = this
      let usersGetRequest = new UsersGetRequest(_this)
      usersGetRequest.setParams({ id: _this.userId })
      await usersGetRequest.fetch(function (content) {
        _this.userData = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })

    },
    async submitEdit() {
      let _this = this

      let usersUpdateByAdminRequest = new UsersUpdateByAdminRequest(_this)

      usersUpdateByAdminRequest.setQuery({ id: _this.userData.id })
      delete this.userData.userGameServices
      let myForm = Helper.makeFormData(this.userData)
      usersUpdateByAdminRequest.setParams(myForm)
      await usersUpdateByAdminRequest.sendFormData(function (content) {
        if (content === 1) {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Failed`,
              icon: 'CloseIcon',
              variant: 'danger',
              text: `This Username has been used.`,
            },
          })
        }
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Successful`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Changes submitted.`,
          },
        })
        _this.getUser()
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })

    return {
      // userData,
    }
  },
}
</script>

<style>

</style>
