<template>
  <div>
    <b-card>
      <!-- Media -->
      <b-media v-if="userData" class="mb-2">
        <template #aside>
          <b-avatar
              ref="previewEl"
              :src="tempProfile?tempProfile : userData.avatarImage"
              :text="avatarText(userData.firstName) +''+avatarText(userData.lastName) "
              rounded
              size="90px"
          />
        </template>
        <h4 class="mb-1">
          {{ userData.firstName + ' ' + userData.lastName }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
          >
            <input
                ref="refInputEl"
                class="d-none"
                type="file"
                @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Edit</span>
            <feather-icon
                class="d-inline d-sm-none"
                icon="EditIcon"
            />
          </b-button>
          <!--          <b-button-->
          <!--              class="ml-1"-->
          <!--              variant="outline-secondary"-->
          <!--              @click="userData.avatarImage = 'Delete'"-->
          <!--          >-->
          <!--            <span class="d-none d-sm-inline">Delete</span>-->
          <!--            <feather-icon-->
          <!--                class="d-inline d-sm-none"-->
          <!--                icon="TrashIcon"-->
          <!--            />-->
          <!--          </b-button>-->
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form v-if="userData">
        <b-row>

          <!-- Field: Username -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Name"
            >
              <b-form-input
                  v-model="userData.firstName"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  Name -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Last Name"
            >
              <b-form-input
                  id="name"
                  v-model="userData.lastName"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  familyName -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Email"
            >
              <b-form-input
                  id="familyName"
                  v-model="userData.email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="User Name"
            >
              <b-form-input
                  id="email"
                  v-model="userData.userName"
                  type="email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="User Status"
            >
              <v-select
                  v-model="userData.userStatus"
                  :clearable="false"
                  :options="userStatuses"
                  :reduce="name => name.value"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Account Activation "
            >
              <v-select
                  v-model="userData.isActive"
                  :clearable="false"
                  :options="[{name:'Active',value:true},{name:'DeActive',value:false}]"
                  :reduce="name => name.value"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="User Type"
            >
              <v-select
                  v-model="userData.userType"
                  :clearable="false"
                  :options="userTypes"
                  :reduce="name => name.value"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
          </b-col>


        </b-row>
      </b-form>


    </b-card>

    <b-card v-if="userData">
      <strong>KYC Information</strong>
      <!-- User Info: Input Fields -->
      <b-form v-if="userData" class="my-1">
        <b-row>
          <!-- Field: Username -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Telegram"
            >
              <b-form-input
                  v-model="userData.telegram"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  Name -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Discord"
            >
              <b-form-input
                  id="name"
                  v-model="userData.discord"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  familyName -->
          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                label="Experience"
            >
              <b-form-input
                  id="familyName"
                  v-model="userData.userExpriences"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="userData.userGameServices.length>0" class="py-1 px-3" cols="12">
            <b-row>
              <b-form-group
                  label="User Game Services"
              >
                <div class="d-flex  align-items-center justify-content-start">
                  <b-badge v-for="(item,idx) in userData.userGameServices" class="mx-25"
                           pill
                           variant="primary"
                  >
                    {{ item.gameServiceName }}
                  </b-badge>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col v-if="userData.userImages.length>0" class="py-1 px-3" cols="12">
            <b-row>
              <b-form-group
                  label="User documents"
              >
                <div class="d-flex  align-items-center justify-content-start">

                  <div
                      v-for="(item,idx) in userData.userImages"
                      class=" position-relative mx-1 border rounded shadow"
                      style="height: 10rem;width: 10rem"
                  >
<!--          <span-->
<!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
<!--              style="width: 2rem;height: 2rem"-->
<!--          >-->
<!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
<!--          </span>-->
                    <img :src="item.imageUrl" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
                  </div>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          variant="primary"
          @click="submitEdit"
      >
        Submit Edit
      </b-button>
    </b-card>
    <b-card v-if="userData">
      <b-row>
        <!-- Field: password -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group label="change password">

            <b-input-group
                v-if="passwordObj"
                class="input-group-merge"
            >
              <b-form-input
                  id="login-password"
                  v-model="passwordObj.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="login-password"
                  placeholder="*****"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>
        <b-col class="d-flex align-items-center mt-1" cols="3">
          <b-button
              class=""
              variant="primary"
              @click="changePasswordByAdmin"
          >
            Change Password
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="orders">
      <b-row>
        <b-col cols="12">
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="orders"
              bordered
              class="position-relative"
              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              sort-by.sync
              striped
          >

            <!-- Column: Status -->
            <template #cell(boosterPrice)="data">
          <span>
               {{ makePrettyPrice(data.item.boosterPrice) + ' ' + getCurrency(data.item.currency) }}
            </span>
            </template>

            <!-- Column: sumPrice -->
            <template #cell(finalPrice)="data">
         <span>
               {{ makePrettyPrice(data.item.finalPrice) + ' ' + getCurrency(data.item.currency) }}
            </span>
            </template>
            <template #cell(orderType)="data">
              <b-badge
                  v-if="data.item.orderType==='Default'"
                  pill
                  class="p-1"
                  variant="primary"
              >
                default
              </b-badge>
              <b-badge
                  v-if="data.item.orderType==='Express'"
                  class="p-1"
                  pill
                  variant="light-danger"
              >
                Express
              </b-badge>
              <b-badge
                  v-if="data.item.orderType==='ForceMajor'"
                  class="p-1"
                  pill
                  variant="danger"
              >
                Force
              </b-badge>
            </template>
            <template #cell(orderStatus)="data">


              <b-badge
                  v-if="data.item.orderStatus==='Waiting'"
                  pill
                  class="p-1"
                  variant="secondary"
              >
                Waiting For payment
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='Payed'"
                  pill
                  class="p-1"
                  variant="light-success"
              >
                Waiting For Boosters To Assign
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='Doing'"
                  pill
                  class="p-1"
                  variant="success"
              >
                Booster Assigned
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='Done'"
                  pill
                  class="p-1"
                  variant="success"
              >
                Booster Completed Order
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='Cancel'"
                  pill
                  class="p-1"
                  variant="danger"
              >
                Waiting For Reassign
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='Frozen'"
                  pill
                  class="p-1"
                  variant="info"
              >
                In Warranty
              </b-badge>
              <b-badge
                  v-if="data.item.orderStatus==='VerifyByAdmin'"
                  pill
                  class="p-1"
                  variant="success"
              >
                Verified
              </b-badge>
            </template>
            <!-- Column: createDate -->
            <template #cell(createdAt)="data">
            <span>


             {{
                new Date(data.item.createdAt).toLocaleDateString('en-US') + ' - ' +
                new Date(data.item.createdAt).toLocaleTimeString('en-US')
              }}
            </span>
            </template>


            <!-- Column: Actions -->
            <template #cell(delete)="data">

              <b-link v-b-modal.modal-delete @click="setSelectedOrder(data.item)">
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </b-link>

            </template>

            <template #cell(detail)="data">


              <b-link :to="{ name: 'apps-orders-info', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" size="20"/>
              </b-link>

            </template>

          </b-table>

        </b-col>
        <b-col cols="12">
          <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalCount"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import { UsersUpdateByAdminRequest ,ChangePasswordByAdmin} from '@/libs/Api/Users'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { OrderGetAllForBoostersRequest, OrderGetByUserIdRequest } from '@/libs/Api/Order'
import Helper from '@/libs/Helper'

export default {
  data() {
    return {
      tempProfile: null,

      passwordFieldType: 'password',
      myTableColumns: [
        {
          key: 'orderCode',
          label: 'order code',
          sortable: true
        },
        {
          key: 'finalPrice',
          label: 'price',
          sortable: true
        },
        {
          key: 'boosterPrice',
          label: 'booster profit',
          sortable: true
        },
        {
          key: 'orderType',
          label: 'order type',
          sortable: true
        },
        {
          key: 'orderStatus',
          label: 'current status',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'create date',
          sortable: true
        },

        {
          key: 'detail',
          label: 'detail'
        },
      ],
      userStatuses: [
        {
          name: 'InActive',
          value: 'InActive'
        },
        {
          name: 'Active',
          value: 'Active'
        },
        {
          name: 'PreRegister (KYC)',
          value: 'PreRegister'
        },
        {}

      ],
      userTypes: [
        {
          name: 'Customer',
          value: 'DefaultUser'
        },
        {
          name: 'Admin',
          value: 'AdminUser'
        },
        {
          name: 'Super Admin',
          value: 'SuperAdmin'
        },
        {
          name: 'Booster',
          value: 'BoosterUser'
        },
        {
          name: 'Currency Seller',
          value: 'CurrencySellerUser'
        },
        {
          name: 'Currency Farmer',
          value: 'CurrencyFarmerUser'
        },
        {
          name: 'Influencer',
          value: 'InfluencerUser'
        },
        {
          name: 'Author',
          value: 'AuthorUser'
        },

      ],
      currentPage: 1,
      perPage: 10,
      totalCount: null,
      searchQuery: '',
      orders: null,
      passwordObj: {
        password: '',
        id: ''
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    name() {
      return this.userData.name + ' ' + this.userData.familyName
    },
  },
  async mounted() {
    await Promise.all([
      this.getUserOrders()
    ])
  },
  watch: {
    currentPage: function () {
      this.getUserOrders()
    }
  },
  methods: {
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    getCurrency(data) {
      return Helper.getCurrencySymbol(data)
    },
    async getUserOrders() {
      if (this.userData?.userType === 'BoosterUser') {
        this.getOrdersForBooster()
      } else {
        this.getUsersOrders()
      }
    },
    async getUsersOrders() {
      let _this = this
      _this.overlay = true
      let data = {
        userId: _this.userData.id,
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery,
      }
      let orderGetByUserIdRequest = new OrderGetByUserIdRequest(_this)
      orderGetByUserIdRequest.setParams(data)
      await orderGetByUserIdRequest.fetch(function (content) {
        _this.overlay = false
        _this.orders = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async getOrdersForBooster() {
      let _this = this
      _this.overlay = true
      let data = {
        boosterId: _this.userData.id,
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery,
      }
      let orderGetAllForBoostersRequest = new OrderGetAllForBoostersRequest(_this)
      orderGetAllForBoostersRequest.setParams(data)
      await orderGetAllForBoostersRequest.fetch(function (content) {
        _this.overlay = false
        _this.orders = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async changePasswordByAdmin() {
      let _this = this
      if (_this.passwordObj.password) {

        let changePasswordByAdmin = new ChangePasswordByAdmin(_this)
        _this.passwordObj.id = this.userData.id
        changePasswordByAdmin.setParams(_this.passwordObj)
        await changePasswordByAdmin.fetch(function (content) {
          if (content.isSuccess) {
            _this.passwordObj = {
              password: '',
              id: this.userData.id
            }
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'Password Changed Successfully',
              },
            })

          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }

        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    inputImageRenderer() {
      let file = this.$refs.refInputEl.files[0]

      this.tempProfile = URL.createObjectURL(file)
      this.userData.avatarImage = file
    },

    submitEdit() {
      this.$emit('submitEdit')
    },
  },
  components: {
    BButton,
    BMedia,
    BBadge,
    BLink,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },

  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const statusOptions = [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Not active',
        value: 2
      },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
